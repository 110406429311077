// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArHomepagePlatformSelect-module--ar-h1-mobile--3EfBA";
export var arH2Mobile = "ArHomepagePlatformSelect-module--ar-h2-mobile--2Vx0p";
export var arH3Mobile = "ArHomepagePlatformSelect-module--ar-h3-mobile--3kwh5";
export var arH4Mobile = "ArHomepagePlatformSelect-module--ar-h4-mobile--21YBr";
export var arH5Mobile = "ArHomepagePlatformSelect-module--ar-h5-mobile--26_AK";
export var arH1 = "ArHomepagePlatformSelect-module--ar-h1--3Ofaa";
export var arH2 = "ArHomepagePlatformSelect-module--ar-h2--3iggl";
export var arH3 = "ArHomepagePlatformSelect-module--ar-h3--28DHd";
export var arH4 = "ArHomepagePlatformSelect-module--ar-h4--2Nj4Q";
export var arH5 = "ArHomepagePlatformSelect-module--ar-h5--i61VT";
export var arParagraphXl = "ArHomepagePlatformSelect-module--ar-paragraph-xl--10hbh";
export var arParagraphXlMedium = "ArHomepagePlatformSelect-module--ar-paragraph-xl-medium--2u7uS";
export var arParagraphBase = "ArHomepagePlatformSelect-module--ar-paragraph-base--pG46f";
export var arParagraphBaseMedium = "ArHomepagePlatformSelect-module--ar-paragraph-base-medium--2DFBD";
export var arParagraphSmall = "ArHomepagePlatformSelect-module--ar-paragraph-small--1RTp3";
export var arParagraphSmallMedium = "ArHomepagePlatformSelect-module--ar-paragraph-small-medium--1jm5i";
export var arParagraphXsmall = "ArHomepagePlatformSelect-module--ar-paragraph-xsmall--I2voM";
export var arParagraphXsmallMedium = "ArHomepagePlatformSelect-module--ar-paragraph-xsmall-medium--33rwO";
export var arLinkMobile = "ArHomepagePlatformSelect-module--ar-link-mobile--2C9Yx";
export var arLinkDesktop = "ArHomepagePlatformSelect-module--ar-link-desktop--204vg";
export var arQuote = "ArHomepagePlatformSelect-module--ar-quote--18vhd";
export var arQuoteMobile = "ArHomepagePlatformSelect-module--ar-quote-mobile--2oOI5";
export var arGradientLight = "ArHomepagePlatformSelect-module--ar-gradient-light--2infw";
export var arGradientBlue = "ArHomepagePlatformSelect-module--ar-gradient-blue--2qA-_";
export var arTransition = "ArHomepagePlatformSelect-module--ar-transition--1IPmJ";
export var container = "ArHomepagePlatformSelect-module--container--3JQeC";
export var contentWrapper = "ArHomepagePlatformSelect-module--contentWrapper--2HDJ4";
export var platforms = "ArHomepagePlatformSelect-module--platforms--5s7Eu";