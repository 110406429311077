// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArHomepageCustomerLove-module--ar-h1-mobile--31rpD";
export var arH2Mobile = "ArHomepageCustomerLove-module--ar-h2-mobile--2h462";
export var arH3Mobile = "ArHomepageCustomerLove-module--ar-h3-mobile--3Ip1W";
export var arH4Mobile = "ArHomepageCustomerLove-module--ar-h4-mobile--3nyu1";
export var arH5Mobile = "ArHomepageCustomerLove-module--ar-h5-mobile--mNXkk";
export var arH1 = "ArHomepageCustomerLove-module--ar-h1--3JSXN";
export var arH2 = "ArHomepageCustomerLove-module--ar-h2--Hy0zG";
export var arH3 = "ArHomepageCustomerLove-module--ar-h3--3Lbnv";
export var arH4 = "ArHomepageCustomerLove-module--ar-h4--Ed_Bt";
export var arH5 = "ArHomepageCustomerLove-module--ar-h5--34mfR";
export var arParagraphXl = "ArHomepageCustomerLove-module--ar-paragraph-xl--2vnNe";
export var arParagraphXlMedium = "ArHomepageCustomerLove-module--ar-paragraph-xl-medium--1u9Pn";
export var arParagraphBase = "ArHomepageCustomerLove-module--ar-paragraph-base--3fYwQ";
export var arParagraphBaseMedium = "ArHomepageCustomerLove-module--ar-paragraph-base-medium--Bs88C";
export var arParagraphSmall = "ArHomepageCustomerLove-module--ar-paragraph-small--1_W74";
export var arParagraphSmallMedium = "ArHomepageCustomerLove-module--ar-paragraph-small-medium--1_G0c";
export var arParagraphXsmall = "ArHomepageCustomerLove-module--ar-paragraph-xsmall--3ENll";
export var arParagraphXsmallMedium = "ArHomepageCustomerLove-module--ar-paragraph-xsmall-medium--1CMtR";
export var arLinkMobile = "ArHomepageCustomerLove-module--ar-link-mobile--1QSc8";
export var arLinkDesktop = "ArHomepageCustomerLove-module--ar-link-desktop--1vmKW";
export var arQuote = "ArHomepageCustomerLove-module--ar-quote--jWRMH";
export var arQuoteMobile = "ArHomepageCustomerLove-module--ar-quote-mobile--dA6P5";
export var arGradientLight = "ArHomepageCustomerLove-module--ar-gradient-light--Y8dFZ";
export var arGradientBlue = "ArHomepageCustomerLove-module--ar-gradient-blue--255g2";
export var arTransition = "ArHomepageCustomerLove-module--ar-transition--DvNGM";
export var container = "ArHomepageCustomerLove-module--container--1WOvD";
export var quotation = "ArHomepageCustomerLove-module--quotation--12ULL";
export var logo = "ArHomepageCustomerLove-module--logo--2En1R";
export var footer = "ArHomepageCustomerLove-module--footer--1Z3QI";